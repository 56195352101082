import HomeHeader from "../components/Home/HomeHeader";
import MainApp from "../components/Home/MainApp";

const Home = () => {
  return (
    <div className="home">
      <HomeHeader />
      <div className="header-info">
        <div className="header-textbox">
          <p>
            Gather with us to celebrate the birth of our Savior at Wildwood Calvary Chapel!
            We look forward to seeing you and your family at one of our Christmas Eve services.
            Reserve your seats below.
          </p>
        </div>
      </div>
      <MainApp />
    </div>
  );
};

export default Home;
